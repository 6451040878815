<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Comments
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        @click="addComment">
        <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" viewBox="0 0 24 24">
          <path d="M12 5v14M5 12h14" />
        </svg>
        <span>Add Comment</span>
      </button>
    </div>
    <div>
      <div class="mb-4 last:mb-0" v-for="comment in comments" :key="comment.comment_id">
        <div :class="`bg-gray-200 p-3 flex flex-col rounded ${comment.is_pinned &&
          'border border-primary'}`
          ">
          <div class="flex gap-2">
            <div v-if="comment.is_pinned">
              <svg class="stroke-current text-primary h-6 w-6" fill="none" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" viewBox="0 0 24 24">
                <path d="M9 4v6l-2 4v2h10v-2l-2 -4v-6" />
                <path d="M12 16l0 5" />
                <path d="M8 4l8 0" />
              </svg>
            </div>
            <p class="whitespace-pre-wrap">{{ comment.comment }}</p>
          </div>
          <div class="flex items-center border-t border-secondary mt-3 pt-3">
            <div class="flex flex-col flex-1 text-sm text-gray-700">
              <p>
                {{ comment.created_by }} on
                {{
                  $moment
                    .unix(comment.created_at)
                    .format("DD/MM/YYYY [at] HH:mm")
                }}
              </p>
              <p v-if="comment.updated_by">
                <i>
                  Updated
                  {{
                    $moment
                      .unix(comment.updated_at)
                      .format("DD/MM/YYYY [at] HH:mm")
                  }}
                  by {{ comment.updated_by }}
                </i>
              </p>
            </div>
            <div class="inline-flex">
              <button @click="deleteComment(comment)" type="button"
                class="inline-flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1">
                <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" viewBox="0 0 24 24">
                  <path
                    d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                </svg>
                <span>Delete</span>
              </button>
              <button
                class="ml-4 inline-flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                type="button" @click="openEditComment(comment)">
                <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" viewBox="0 0 24 24">
                  <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                </svg>
                <span>Edit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center" v-if="!comments || comments.length == 0">
      No Comments have been added yet
    </p>

    <portal to="overlay-outlet">
      <panel :showing="addEditCommentPanelOpen" @close="addEditCommentPanelOpen = false"
        :title="editingComment.comment_id ? 'Edit Comment' : 'Add Comment'">
        <faq-add-edit-comment :comment="editingComment" @complete="closeCommentPanel" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
import FaqAddEditComment from "@/components/Faqs/Comments/edit/AddEditComment.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";

export default {
  name: "FaqComments",
  props: {
    contextName: {
      type: String,
      default: "Demo",
    },
    faqId: String
  },
  components: {
    FaqAddEditComment,
    ConfirmAlert,
    Panel,
    Notification,
  },
  data() {
    return {
      addEditCommentPanelOpen: false,
      editingComment: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      comments: [],
    };
  },
  async mounted() {
    await this.loadComments();
  },
  computed: {
    sortedComments: function () {
      return [
        ...this.comments.filter((c) => c.is_pinned),
        ...this.comments.filter((c) => !c.is_pinned),
      ];
    },
  },
  methods: {
    loadComments: async function () {
      this.viewState = "Loading";

      try {
        let result = await this.faqService.getCommentsByfaqId(
          this.faqId
        );

        this.comments = result;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    addComment: function () {
      this.editingComment = { comment: "" };
      this.addEditCommentPanelOpen = true;
    },
    closeCommentPanel: async function (comment) {
      try {
        let isUpdate = false;

        if (comment.comment_id) {
          // Update
          isUpdate = true;

          await this.faqService.updateComment(this.faqId, comment);

          let result = await this.faqService.getCommentsByfaqId(
            this.faqId
          );

          this.comments = result;
        } else {
          let result = await this.faqService.createComment(
            this.faqId,
            comment
          );

          this.comments.unshift(result);
        }

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: `Comment ${isUpdate ? "updated" : "added"}`,
                  close: onClose,
                },
              },
              `Comment has been successfully ${isUpdate ? "updated" : "added"}`
            );
          },
          {
            position: "top-right",
          }
        );
        this.addEditCommentPanelOpen = false;
        this.editingComment = {};
      } catch (err) {
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There has been a problem saving your comments"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    openEditComment(comment) {
      this.editingComment = comment;

      this.addEditCommentPanelOpen = true;
    },
    handleDeleteComment: async function (result) {
      if (result) {
        try {
          await this.faqService.deleteComment(this.faqId, this.confirmAlert.data.comment_id);

          let idx = this.$_.findIndex(this.comments, (n) => {
            return n.comment_id == this.confirmAlert.data.comment_id;
          });

          this.$delete(this.comments, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Success",
                    close: onClose,
                  },
                },
                "Note deleted"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the comment"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteComment(comment) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteComment,
        message:
          "Are you sure you want to delete this comment? Cannot be undone",
        showing: true,
        data: comment,
      };
    },
  },
};
</script>
